import React from 'react';
import Layout from '../components/Layout';
import content from "../content/contact"

import "../components/styles/contact.css"

const ContactPage = () => (
  <Layout>
    <div className="contact-wrapper">
      <a href={'mailto:' + content.email}>{content.email}</a>
    </div>
  </Layout>
);

export default ContactPage;
